<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      lineId: this.$route.query.lineid ? this.$route.query.lineid : "",

      // new state
      lineProfile: ""
    };
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_Broadcast })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.checkTerm(profile.userId);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    checkTerm(lineId) {
      this.$router.replace({
        path: "/policy",
        query: {
          lineid: lineId,
          isClose: "true",
          term: true
        }
      });
      //   let myHeaders = new Headers();
      //   myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      //   myHeaders.append("Content-Type", "application/json");
      //   let requestOptions = {
      //     method: "GET",
      //     headers: myHeaders,
      //     redirect: "follow"
      //   };
      //   fetch(
      //     `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
      //     requestOptions
      //   )
      //     .then(response => response.json())
      //     .then(result => {
      //       this.$router.push({
      //         path: "/policy",
      //         query: {
      //           lineid: lineId,
      //           isClose: "true",
      //           term: true
      //         }
      //       });
      //       if (result.result == 1) {
      //         if (!result.detail) {
      //           // let routeData = this.$router.resolve({
      //           //   name: "policy",
      //           //   query: { lineid: lineId }
      //           // });
      //           // window.open(routeData.href, "_blank");
      //         }
      //       } else {
      //         // let routeData = this.$router.resolve({
      //         //   name: "policy",
      //         //   query: { lineid: lineId }
      //         // });
      //         // window.open(routeData.href, "_blank");
      //         this.$router.push({
      //           path: "/policy",
      //           query: {
      //             lineid: lineId,
      //             term: true
      //           }
      //         });
      //       }
      //     })
      //     .catch(error => {
      //       console.error(error);
      //     });
    }
  }
};
</script>
